<template>
  <b-card title="Acciones">
    <transition name="bounce">
      <b-row class="mb-3" v-if="isCreate">
        <b-col md="4">
          <div class="form-group">
            <label>Acción</label>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Descripción"
              v-model="action"
            />
          </div>
        </b-col>
        <b-col md="12">
          <button
            class="btn btn-sm btn-primary btn-circle mr-2"
            :disabled="!action"
            @click="saveAction()"
          >
            <i class="la la-save"></i>
            {{ creating ? "Guardando..." : "Guardar" }}
          </button>
          <button
            class="btn btn-sm btn-danger btn-circle"
            @click="
              () => {
                isCreate = false;
                action = null;
              }
            "
          >
            <i class="flaticon-circle"></i>
          </button>
        </b-col>
      </b-row>
    </transition>

    <transition name="slide-fade">
      <b-row v-if="items.length">
        <b-col>
          <DxDataGrid
            :ref="gridRefName"
            :data-source="items"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :columnAutoWidth="true"
            :allow-column-resizing="true"
            :allow-column-reordering="true"
            id="settlementTable"
          >
            <DxExport :enabled="true" />
            <DxColumnChooser
              emptyPanelText="Arrastre una columna aquí para ocultar"
              :enabled="true"
              title="Arrastre columna aquí..."
            />
            <DxColumnFixing :enabled="true" />
            <DxGroupPanel
              :visible="false"
              emptyPanelText="Arrastre una columna aquí para agrupar"
            />
            <DxSearchPanel
              :visible="true"
              :width="240"
              placeholder="Buscar..."
            />
            <DxPaging :page-size="30" />
            <DxPager
              :visible="true"
              infoText="Página {0} de {1} ({2} registros)"
              :allowed-page-sizes="[5, 15, 30, 50]"
              display-mode="full"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
            />
            <DxHeaderFilter :visible="true" />
            <DxFilterRow :visible="true">
              <DxOperationDescriptions
                equal="Igual a"
                notEqual="Diferente de"
                startsWith="Empieza con"
                endsWith="Termina con"
                contains="Contiene"
                between="Entre"
                notContains="No contiene"
                lessThanOrEqual="Menor o igual"
                greaterThanOrEqual="Mayor o igual"
                lessThan="Menor que"
                greaterThan="Mayor que"
              />
            </DxFilterRow>
            <DxGroupPanel :visible="true" />
            <DxColumn
              caption="Acción"
              data-field="descripcion"
              alignment="center"
            />
            <DxColumn
              caption=""
              data-type="buttons"
              alignment="center"
              cell-template="Buttons"
              width="130"
            />

            <DxToolbar>
              <DxItem location="after" template="refreshTemplate" />
              <DxItem location="before" template="createTemplate" />
              <DxItem name="columnChooserButton" />
              <DxItem name="groupPanel" />
              <DxItem name="exportButton" />
              <DxItem name="searchPanel" />
            </DxToolbar>
            <template #createTemplate>
              <DxButton
                type="default"
                styling-mode="contained"
                icon="plus"
                text="Agregar"
                :disabled="isCreate"
                @click="
                  () => {
                    isCreate = true;
                    action = null;
                  }
                "
              />
            </template>
            <template #refreshTemplate>
              <DxButton
                type="default"
                styling-mode="outlined"
                icon="refresh"
                @click="refreshDataGrid()"
              />
            </template>
            <template #Buttons="{ data: cellData }">
              <div class="group-buttons text-center">
                <button
                  class="btn btn-sm btn-circle"
                  v-if="can('delete-actions')"
                  @click="deleteAction(cellData.data.accion_id)"
                >
                  <i class="flaticon2-trash text-danger"></i>
                </button>
                <router-link
                  class="btn btn-sm btn-circle"
                  v-if="can('update-actions')"
                  :to="{
                    name: 'actionUpdate',
                    params: { id: cellData.data.accion_id },
                  }"
                >
                  <i class="flaticon2-edit text-success"></i>
                </router-link>
              </div>
            </template>
          </DxDataGrid>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <div v-if="!loading">
            <div class="text-center">
              <img src="assets/img/empty.png" alt="Empty" />
              <p class="text-muted">No hay información para mostrar</p>
            </div>
          </div>
          <div class="text-center" v-else>
            <img src="assets/img/loader.gif" alt="Loading..." />
          </div>
        </b-col>
      </b-row>
    </transition>
  </b-card>
</template>

<script>
import axios from "axios";
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxColumnChooser,
  DxColumnFixing,
  DxToolbar,
  DxItem,
  DxColumn,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import can from "@/permission";
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxOperationDescriptions,
    DxExport,
    DxGroupPanel,
    DxColumnChooser,
    DxColumnFixing,
    DxToolbar,
    DxItem,
    DxButton,
    DxColumn,
  },
  data() {
    return {
      loading: false,
      creating: false,
      gridRefName: "dataGrid",
      items: [],
      isCreate: false,

      action: null,
    };
  },
  mounted() {
    this.loadActions();
  },
  methods: {
    can: can,
    saveAction() {
      if (this.action) {
        this.creating = true;
        axios
          .post("/acciones", { descripcion: this.action })
          .then((res) => {
            if (res.data.message == "success") {
              this.loadActions();
              this.$swal.fire({
                title: "Información",
                text: res.data.information,
                icon: "success",
              });
              this.action = null;
            } else {
              this.$swal.fire({
                title: "Información",
                text: res.data.information,
                icon: "error",
              });
            }
            this.creating = false;
          })
          .catch((e) => {
            this.creating = false;
            console.log(e);
          });
      }
    },
    deleteAction(id) {
      if (can("delete-actions")) {
        this.$swal
          .fire({
            title: "¿Desea eliminar el registro?",
            text: "Esta información dejará de verla la brigada móvil",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Eliminar!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              axios
                .delete(`/acciones/${id}`)
                .then((res) => {
                  if (res.data.message == "success") {
                    this.$swal.fire(
                      "Eliminado!",
                      res.data.information,
                      "success"
                    );
                    this.loadActions();
                  } else {
                    this.$swal.fire(
                      "Eliminado!",
                      res.data.information,
                      "success"
                    );
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          });
      }
      console.log(id);
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${this.$route.name}.xlsx`
          );
        });
      });
      e.cancel = true;
    },
    loadActions() {
      this.loading = true;
      this.items = [];
      axios
        .get(`/acciones`)
        .then((res) => {
          this.loading = false;
          this.items = res.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    refreshDataGrid() {
      this.loadActions();
    },
  },
};
</script>
